<template>
  <div>
    <!-- 页顶 -->
    <div :style="{ height: height === 20 ? '20px' : `${height}px` }"></div>
    <div class="TopOfPage">
      <span class="SPan">-</span>
      <div class="report">{{ report }}</div>
      <span class="SPan">-</span>
    </div>
    <!-- 页顶 -->

    <!-- 面试有招顶部 -->
    <div class="PageHeader">
      <!-- <span class="HeaderImg"><img src="@/assets/image/resume/logo.png" alt="" /></span> -->
      <div class="HeaderTop">
        <!-- <span class="TopLeft"><img src="@/assets/image/resume/面试有招 .png" alt="" /></span> -->
        <span class="TopRight">导出时间: {{ this.dataTmie }} {{ this.time }}</span>
      </div>
    </div>
    <!-- 面试有招顶部 -->
  </div>
</template>

<script>
export default {
  name: 'ResumeReportHeader', //简历诊断报告头部
  props: {
    height: {
      //控制对页码顶部的距离
      tyep: String,
      default: '20'
    },
    report: {
      //页顶报告文字
      tyep: String,
      default: '简历诊断报告'
    }
  },
  data() {
    return {
      tiem: ''
    }
  },
  created() {
    this.TimeData()
  },
  methods: {
    TimeData() {
      //年
      let year = new Date().getFullYear()
      //月份是从0月开始获取的，所以要+1;
      let month = new Date().getMonth() + 1
      //日
      let day = new Date().getDate()
      //时
      let hour = new Date().getHours()
      //分
      let minute = new Date().getMinutes()
      if (month < 10) month = '0' + month
      this.dataTmie = year + '-' + month + '-' + day
      if (hour < 10) hour = '0' + hour
      if (minute < 10) minute = '0' + minute
      this.time = hour + ':' + minute
    }
  }
}
</script>

<style scoped lang="stylus">
.TopOfPage {
  display: flex;
  justify-content: center;
  .SPan {
    font-size: 18px;
    line-height: 20px;
    color: #878A99;
  }
  .report {
    font-size: 16px;
    font-weight: 400;
    color: #878A99;
    margin: 0 8px;
  }
}
.PageHeader {
  display: flex;
  align-items: center;
  margin-top: 15px;
  .HeaderImg {
    width: 30px;
    height: 30px;
    margin-right: 5px
    img {
      width: 100%;
      height: 100%;
    }
  }
  .HeaderTop {
    width: 1130px;
    border-bottom: 1px solid #383A4D;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    .TopLeft {
      width: 84px;
      height: 17px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .TopRight {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #878A99;
    }
  }
}
</style>
