<template>
  <div>
    <!-- 顶部 -->
    <div class="title-top">
      <div class="TopBox">
        <div class="return" @click="close">
          <img src="@/assets/image/resumeLogin/箭头.png" alt="" class="return-img" />
          <div class="return-text">返回</div>
        </div>
        <div class="btn-box">
          <!-- <el-button @click="printerFn" icon="el-icon-printer" class="printerBtn">打印</el-button> -->
          <div class="pdfBT" @click="printFn">一键导出</div>
        </div>
      </div>
    </div>
    <!-- 顶部 -->

    <!-- 版心 -->
    <div class="typeArea" id="print-content" ref="print">
      <!-- 页顶 -->
      <ResumReportHeade />
      <!-- 页顶 -->

      <!-- 基础信息 -->
      <!-- <div class="information" v-if="userInfo">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
          <span class="mationText">基础信息</span>
        </div>

        <div class="informationCentre">
          <div class="CentreMessage">
            <div class="MessageTop">
              <span class="name">{{ userInfo.name }}</span>
              <span class="gender">
                {{ userInfo.gender === 1 ? '男' : userInfo.gender === 2 ? '女' : '' }}
              </span>
              <span class="age">{{ userInfo.age }}</span>
              <span class="education" v-if="userInfo.degree">
                {{
                  userInfo.degree == 1
                    ? '专科'
                    : userInfo.degree == 2
                    ? '本科'
                    : userInfo.degree == 3
                    ? '硕士'
                    : userInfo.degree == 4
                    ? '博士'
                    : '其他'
                }}
              </span>
              <span class="work">
                {{ userInfo.identity == 1 ? '在校生' : userInfo.identity == 2 ? '应届生' : '社会求职' }}
              </span>
            </div>
          </div>
          <div class="CenterBottom" v-if="userInfo.school_name">学校名称：{{ userInfo.school_name }}</div>
        </div>

        <div class="informationBottom">
          <div class="informationOne">
            <div>
              手机号:
              <span>{{ userInfo.phone_number }}</span>
            </div>
            <div>
              邮箱:
              <span>{{ userInfo.email }}</span>
            </div>
          </div>
          <div class="informationOne">
            <div>
              <div>
                期望薪资:
                <span>
                  {{ userInfo && userInfo.intention ? userInfo.intention[0].minwage_name : '' }}-{{
                    userInfo && userInfo.intention ? userInfo.intention[0].maxwage_name : ''
                  }}
                </span>
              </div>
            </div>
            <div>
              期望职位:
              <span>{{ userInfo && userInfo.intention ? userInfo.intention[0].category_name : '' }}</span>
            </div>
          </div>
          <div class="informationOne">
            <div>
              出生年月:
              <span>{{ userInfo.date_of_birth }}</span>
            </div>
            <div v-if="userInfo.current_location">
              现住城市:
              <span>{{ userInfo.current_location }}</span>
            </div>
          </div>
        </div>
      </div> -->
      <basicInformationComponent :id="userId" v-if="userId" />
      <!-- 基础信息 -->

      <!--评分数据 -->
      <div class="ScorimgData">
        <div class="ScorimgDataTop">
          <span class="mationImg">
            <img src="@/assets/image/resume/icon.png" alt="" />
          </span>
          <span class="mationText">评分数据</span>
        </div>

        <div class="ScorimgDataCenten">
          <div class="CentenOne">
            <div class="OneNumber">
              {{ callBackObj.total_score }}
              <span class="Fen">分</span>
            </div>
            <div class="Evaluation">简历综合评分</div>
            <div class="Assess">
              你的简历与「{{
                callBackObj.position_name
              }}」的匹配情况，建议根据目标岗位的任职要求修改简历哦
            </div>
          </div>

          <div class="TwoEcharts">
            <div id="myChart" :style="{ width: '450px', height: '290px' }"></div>
          </div>

          <div class="ThreeEnd">
            <div class="Yleld">
              <div class="YleldBox">
                <div class="BoxImg">
                  <img src="@/assets/image/resume/icon(1).png" alt="" />
                </div>
                <div class="BoxRigth">
                  <div class="Percent">{{ callBackObj.standard }}%</div>
                  <div class="Pass">诊断合格率</div>
                </div>
              </div>
            </div>

            <div class="ThreeBoottomBox">
              <div class="boxOne">
                <div class="internet">{{ callBackObj.position_name }}</div>
                <div class="diagnose">诊断目标岗位</div>
              </div>
              <div class="boxTow">
                <div class="internet">{{ callBackObj.count_times }}</div>
                <div class="diagnose">诊断次数</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--评分数据 -->

      <!-- 能力项匹配 -->
      <div class="CapacityTerm question-table" v-if="callBackObj.hard_skill_items">
        <div class="CapacityTermTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
          <span class="mationText">能力项匹配</span>
        </div>

        <!-- 表格列表区域 -->
        <div class="TabularList">
          <table class="ListTable">
            <tr>
              <th style="width: 40%">工作能力</th>
              <th style="width: 22.5%">简历匹配</th>
              <th style="width: 22.5%">重要程度</th>
            </tr>
            <tr v-for="(item, index) in callBackObj.work_exp_items.slice(0, 14)" :key="index">
              <td height="50px" style="color: #383a4d">{{ item.title }}</td>
              <td height="50px" class="TdImg">
                <img src="@/assets/image/resume/勾.png" alt="" v-if="!item.status" />
                <img src="@/assets/image/resume/X.png" alt="" v-else />
              </td>
              <td height="50px">
                <font
                  :style="{
                    color:
                      item.importance_level == 2
                        ? '#008AFF'
                        : item.importance_level == 1
                        ? '#E8723C'
                        : '#5D6868'
                  }"
                >
                  {{ item.importance_note }}
                </font>
              </td>
            </tr>
          </table>

          <table class="ListTable">
            <tr>
              <th style="width: 40%">硬技能</th>
              <th style="width: 22.5%">简历匹配</th>
              <th style="width: 22.5%">重要程度</th>
            </tr>
            <tr
              v-for="(item, index) in callBackObj.hard_skill_items.slice(0, 14)"
              :key="index + 's'"
            >
              <td height="50px" style="color: #383a4d">{{ item.title }}</td>
              <td height="50px" class="TdImg">
                <img src="@/assets/image/resume/勾.png" alt="" v-if="!item.status" />
                <img src="@/assets/image/resume/X.png" alt="" v-else />
              </td>
              <td height="50px">
                <font
                  :style="{
                    color:
                      item.importance_level == 2
                        ? '#008AFF'
                        : item.importance_level == 1
                        ? '#E8723C'
                        : '#5D6868'
                  }"
                >
                  {{ item.importance_note }}
                </font>
              </td>
            </tr>
          </table>

          <table class="ListTable">
            <tr>
              <th style="width: 40%">软技能</th>
              <th style="width: 22.5%">简历匹配</th>
              <th style="width: 22.5%">重要程度</th>
            </tr>
            <tr v-for="(item, index) in callBackObj.soft_skill_items.slice(0, 14)" :key="index">
              <td height="50px" style="color: #383a4d">{{ item.title }}</td>
              <td height="50px" class="TdImg">
                <img src="@/assets/image/resume/勾.png" alt="" v-if="!item.status" />
                <img src="@/assets/image/resume/X.png" alt="" v-else />
              </td>
              <td height="50px">
                <font
                  :style="{
                    color:
                      item.importance_level == 2
                        ? '#008AFF'
                        : item.importance_level == 1
                        ? '#E8723C'
                        : '#5D6868'
                  }"
                >
                  {{ item.importance_note }}
                </font>
              </td>
            </tr>
          </table>
        </div>
        <!-- 表格列表区域 -->
      </div>
      <!-- 能力项匹配 -->

      <!-- 第一页的结尾 -->
      <ResumReportFoot :pages="`1/2`" class="question-table" />
      <!-- 第一页的结尾 -->

      <!-- 第二页开始 -->
      <!-- 页顶 -->
      <ResumReportHeade :height="20" class="question-table" />
      <!-- 页顶 -->
      <!-- 基础信息 -->
      <div class="basicBox question-table">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
          <span class="mationText">基础信息</span>
          <span class="Finalscore">
            {{ `${callBackObj.basic_info_score}分` }}
          </span>
          <span class="TotalScore">
            {{ `/${callBackObj.basic_info_total_score}分` || `/20分` }}
          </span>
        </div>

        <div class="BasiLayout">
          <div class="BasiLaoutOne">
            <div class="dashed">
              <span>基础信息亮点 :</span>
            </div>
            <div v-if="callBackObj.basic_info_lightspot && callBackObj.basic_info_lightspot.length">
              <div v-for="(item, index) in callBackObj.basic_info_lightspot" :key="index">
                <div class="BasiCentenTxet">{{ item.title }}</div>
                <div v-for="(item, i) in item.item" :key="i">
                  <div class="BasiBottomtextBox">
                    <div>{{ `${i + 1}` }} {{ item.lightspot }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- <div
                v-for="(item, index) in callBackObj.basic_info_lightspot"
                :key="index"
                v-if="index == 0"
              >
                你的{{ item.title }}暂时没有发现与该岗位匹配的亮点，请认真描
                述你的过往经验哦！
              </div> -->
              <div>你的基础信息暂时没有发现与该岗位匹配的亮点，请认真描 述你的过往经验哦！</div>
              <div class="BoxImg">
                <img src="@/assets/image/resume/icon(3).png" alt="" />
              </div>
            </div>
          </div>
          <div class="Solid"></div>
          <div class="BasiLaoutTow">
            <div class="dashed">
              <span>诊断优化建议 :</span>
            </div>
            <div
              v-if="callBackObj.basic_info_suggestion && callBackObj.basic_info_suggestion.length"
            >
              <div v-for="(item, index) in callBackObj.basic_info_suggestion" :key="index">
                <div class="BasiCentenTxet">{{ item.title }}</div>
                <div class="BasiBottomtextBox">
                  <div v-for="(item1, index) in item.item" :key="index">
                    {{ `${index + 1}` }} {{ item1.suggestion }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- <div
                class="BasiBottomtextBox"
                v-for="(item, index) in callBackObj.basic_info_suggestion"
                :key="index"
                v-if="index == 0"
              >
                你的{{ item.title }}完善得非常好,暂时没有什么可以建议的了！
              </div> -->
              <div>你的简历信息完善得非常好,暂时没有什么可以建议的了！</div>
              <div class="TowImg">
                <img src="@/assets/image/resume/icon(2).png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 基础信息 -->

      <!-- 个人经历 -->
      <div class="basicBox question-table">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
          <span class="mationText">{{ callBackObj.personal_exp_title }}</span>
          <span class="Finalscore">
            {{ `${callBackObj.personal_exp_score}分` }}
          </span>
          <span class="TotalScore">
            {{ `/${callBackObj.personal_exp_total_score}分` }}
          </span>
        </div>

        <div class="BasiLayout">
          <div class="BasiLaoutOne">
            <div class="dashed">
              <span>个人经历亮点 :</span>
            </div>
            <div
              v-if="callBackObj.personal_exp_lightspot && callBackObj.personal_exp_lightspot.length"
            >
              <div v-for="(item, index) in callBackObj.personal_exp_lightspot" :key="index">
                <div class="BasiCentenTxet">{{ item.title }}</div>
                <div v-for="(item, i) in item.item" :key="i">
                  <div class="BasiBottomtextBox">
                    <div>{{ `${i + 1}.` }}{{ item.lightspot }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- <div
                v-for="(item, index) in callBackObj.personal_exp_lightspot"
                :key="index"
                v-if="index == 0"
              >
                你的{{ item.title }}暂时没有发现与该岗位匹配的亮点，请认真描
                述你的过往经验哦！
              </div> -->
              <div>你的个人经历暂时没有发现与该岗位匹配的亮点，请认真描 述你的过往经验哦！</div>
              <div class="BoxImg">
                <img src="@/assets/image/resume/icon(3).png" alt="" />
              </div>
            </div>
          </div>
          <div class="Solid"></div>
          <div class="BasiLaoutTow">
            <div class="dashed">
              <span>诊断优化建议 :</span>
            </div>
            <div
              v-if="
                callBackObj.personal_exp_suggestion && callBackObj.personal_exp_suggestion.length
              "
            >
              <div v-for="(item, index) in callBackObj.personal_exp_suggestion" :key="index">
                <div class="BasiCentenTxet">{{ item.title }}</div>
                <div class="BasiBottomtextBox">
                  <div v-for="(item1, index) in item.item" :key="index">
                    {{ `${index + 1}` }} {{ item1.suggestion }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- <div
                class="BasiBottomtextBox"
                v-for="(item, index) in callBackObj.personal_exp_suggestion"
                :key="index"
                v-if="index == 0"
              >
                你的{{ item.title }}完善得非常好,暂时没有什么可以建议的了！
              </div> -->
              <div>你的简历信息完善得非常好,暂时没有什么可以建议的了！</div>
              <div class="TowImg">
                <img src="@/assets/image/resume/icon(2).png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 个人经历 -->

      <!-- 人岗匹配 -->
      <div class="basicBox question-table">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
          <span class="mationText">人岗匹配</span>
          <span class="Finalscore">
            {{ `${callBackObj.post_match_score}分` }}
          </span>
          <span class="TotalScore">
            {{ `/${callBackObj.post_match_total_score}分` }}
          </span>
        </div>

        <div class="BasiLayout">
          <div class="BasiLaoutOne">
            <div class="dashed">
              <span>人岗匹配亮点 :</span>
            </div>
            <div v-if="callBackObj.post_match_lightspot && callBackObj.post_match_lightspot.length">
              <div v-for="(item, index) in callBackObj.personal_exp_lightspot" :key="index">
                <div class="BasiCentenTxet">{{ item.title }}</div>
                <div v-for="(item, i) in item.item" :key="i">
                  <div class="BasiBottomtextBox">
                    <div>{{ `${index + 1}.` }}{{ item.lightspot }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- <div
                v-for="(item, index) in callBackObj.post_match_lightspot"
                :key="index"
                v-if="index == 0"
              ></div> -->
              <div>你的简历暂时没有发现与该岗位匹配的亮点，请认真描述你的过往经验哦！</div>
              <div class="BoxImg">
                <img src="@/assets/image/resume/icon(3).png" alt="" />
              </div>
            </div>
          </div>
          <div class="Solid"></div>
          <div class="BasiLaoutTow">
            <div class="dashed">
              <span>诊断优化建议 :</span>
            </div>
            <div
              v-if="callBackObj.post_match_suggestion && callBackObj.post_match_suggestion.length"
            >
              <div v-for="(item, index) in callBackObj.post_match_suggestion" :key="index">
                <div class="BasiCentenTxet">{{ item.title }}</div>
                <div class="BasiBottomtextBox">
                  <div v-for="(item1, index) in item.item" :key="index">
                    {{ `${index + 1}` }} {{ item1.suggestion }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- <div
                class="BasiBottomtextBox"
                v-for="(item, index) in callBackObj.personal_exp_suggestion"
                :key="index"
                v-if="index == 0"
              >
                你的{{ item.title }}完善得非常好,暂时没有什么可以建议的了！
              </div> -->
              <div>你的简历信息完善得非常好,暂时没有什么可以建议的了！</div>
              <div class="TowImg">
                <img src="@/assets/image/resume/icon(2).png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 人岗匹配 -->

      <!-- 其他信息 -->
      <div class="OtherInformation question-table">
        <div class="MtainBox">
          <!-- 左边排版 -->
          <div class="BoxLeft">
            <div class="informationTop">
              <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
              <span class="mationText">其他信息</span>
              <span class="Finalscore">{{ `${callBackObj.other_info_score}分` }}</span>
              <span class="TotalScore">{{ `/${callBackObj.other_info_total_score}分` }}</span>
            </div>
            <!-- 左边上-->
            <div class="BoxLeftTop">
              <div class="dashed"><span>其他信息亮点:</span></div>
              <div
                v-if="callBackObj.other_info_lightspot && callBackObj.other_info_lightspot.length"
              >
                <div v-for="(item, index) in callBackObj.other_info_lightspot" :key="index">
                  <div>
                    <div class="BasiCentenTxet" v-if="callBackObj.other_info_lightspot.length">
                      {{ item.title }}
                    </div>
                    <div v-if="callBackObj.other_info_lightspot.length">
                      <div
                        class="BasiBottomtextBox"
                        v-for="(item1, index) in item.item"
                        :key="index"
                      >
                        {{ item.item.length > 1 ? `${index + 1}.` : '' }}{{ item1.lightspot }}
                      </div>
                    </div>
                    <div v-else class="BasiBottomtextBox">
                      你的{{ item.title }}暂时没有发现与该岗位匹配的亮点，请认真描述你的过往经验哦！
                    </div>
                    <div class="BoxImg" v-if="!callBackObj.other_info_lightspot.length">
                      <img src="@/assets/image/resume/icon(2).png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                你的简历信息暂时没有发现与其他信息匹配的亮点，请认真描述你的过往经验哦！
                <div class="BoxImg">
                  <img src="@/assets/image/resume/icon(3).png" alt="" />
                </div>
              </div>
            </div>

            <!-- 左边上-->

            <!-- 左边下 -->
            <div class="BoxLeftBootom">
              <div class="dashed"><span>诊断优化建议:</span></div>
              <div
                v-if="callBackObj.other_info_suggestion && callBackObj.other_info_suggestion.length"
              >
                <div v-for="(item, index) in callBackObj.other_info_suggestion" :key="index">
                  <div>
                    <div
                      class="BasiCentenTxet"
                      v-if="
                        callBackObj.other_info_suggestion &&
                        callBackObj.other_info_suggestion.length
                      "
                    >
                      {{ item.title }}
                    </div>

                    <div
                      v-if="
                        callBackObj.other_info_suggestion &&
                        callBackObj.other_info_suggestion.length
                      "
                    >
                      <div
                        class="BasiBottomtextBox"
                        v-for="(item1, index) in item.item"
                        :key="index"
                      >
                        {{ item.item.length > 1 ? `${index + 1}.` : '' }}{{ item1.suggestion }}
                      </div>
                    </div>
                    <div v-else class="BasiBottomtextBox">
                      你的{{ item.title }}非常好，暂时没有什么可以建议的了！
                    </div>
                    <div
                      class="Boximg"
                      v-if="
                        callBackObj.other_info_suggestion &&
                        !callBackObj.other_info_suggestion.length
                      "
                    >
                      <img src="@/assets/image/resume/icon(2).png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                你的简历信息完善得非常好,暂时没有什么可以建议的了！
                <div class="Boximg">
                  <img src="@/assets/image/resume/icon(2).png" alt="" />
                </div>
              </div>
            </div>
            <!-- 左边下 -->
          </div>
          <div class="solid"></div>
          <!-- 右边排版 -->
          <div class="BoxLeft">
            <div class="informationTop">
              <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
              <span class="mationText">排版设计</span>
              <span class="Finalscore">{{ `${callBackObj.structure_score}分` }}</span>
              <span class="TotalScore">{{ `/${callBackObj.structure_total_score}分` }}</span>
            </div>
            <!-- 右边上 -->
            <div class="BoxLeftTop">
              <div class="dashed"><span>排版设计亮点:</span></div>
              <div v-if="callBackObj.structure_lightspot && callBackObj.structure_lightspot.length">
                <div v-for="(item, index) in callBackObj.structure_lightspot" :key="index">
                  <div>
                    <div class="BasiCentenTxet" v-if="callBackObj.structure_lightspot.length">
                      {{ item.title }}
                    </div>
                    <div v-if="callBackObj.structure_lightspot.length">
                      <div
                        class="BasiBottomtextBox"
                        v-for="(item1, index) in item.item"
                        :key="index"
                      >
                        {{ item.item.length > 1 ? `${index + 1}.` : '' }}{{ item1.lightspot }}
                      </div>
                    </div>
                    <div v-else class="BasiBottomtextBox">
                      你的{{ item.title }}非常好，暂时没有什么可以建议的了！
                    </div>
                    <div class="BoxImg" v-if="!callBackObj.structure_lightspot.length">
                      <img src="@/assets/image/resume/icon(2).png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                你的简历信息暂时没有发现与排版设计匹配的亮点，请认真描述你的过往经验哦！
                <div class="BoxImg">
                  <img src="@/assets/image/resume/icon(3).png" alt="" />
                </div>
              </div>
            </div>

            <!-- 右边上 -->

            <!-- 右边下 -->
            <div class="BoxLeftBootom">
              <div class="dashed"><span>诊断优化建议:</span></div>
              <div
                v-if="callBackObj.structure_suggestion && callBackObj.structure_suggestion.length"
              >
                <div v-for="(item, index) in callBackObj.structure_suggestion" :key="index">
                  <div class="BasiCentenTxet" v-if="callBackObj.structure_suggestion.length">
                    {{ item.title }}
                  </div>
                  <div v-if="callBackObj.structure_suggestion.length">
                    <div class="BasiBottomtextBox" v-for="(item1, index) in item.item" :key="index">
                      {{ item.item.length > 1 ? `${index + 1}.` : '' }}{{ item1.suggestion }}
                    </div>
                  </div>
                  <div v-else class="BasiBottomtextBox">
                    你的{{ item.title }}非常好，暂时没有什么可以建议的了！
                  </div>
                  <div class="Boximg" v-if="!callBackObj.structure_suggestion.length">
                    <img src="@/assets/image/resume/icon(2).png" alt="" />
                  </div>
                </div>
              </div>
              <div v-else>
                你的简历信息完善得非常好,暂时没有什么可以建议的了！
                <div class="Boximg">
                  <img src="@/assets/image/resume/icon(2).png" alt="" />
                </div>
              </div>
            </div>
            <!-- 右边下 -->
          </div>
        </div>
      </div>
      <!-- 其他信息 -->

      <!-- 第二页结尾 -->
      <ResumReportFoot :pages="`2/2`" class="question-table" />
      <!-- 第二页结尾 -->
    </div>
    <!-- <el-button class="acticedBtn" @click="printFn" type="primary">一键导出</el-button> -->
    <!-- 版心 -->

    <el-dialog title="报告发送至邮箱" :visible.sync="dialogVisible" width="30%" @close="resetForm">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-row>
          <el-col :span="18">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model.trim="form.email" @keyup.enter.native="submitForm" />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item>
              <el-button type="primary" @click="submitForm" class="suint" v-loading="loadingSubmit">
                确定
              </el-button>
              <el-button @click="resetForm">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PdfLoader from '@/utils/htmlpdf.js'
import ResumReportHeade from '@/components/ResumeReportHeader' //诊断报告的头部组件
import ResumReportFoot from '@/components/ResumeReportFoot' //诊断报告的脚部组件
import basicInformationComponent from '@/components/basicInformationComponent.vue' //基础信息组件
import { mapActions, mapState } from 'vuex'
import { upyunUrl } from '@/api/config'
export default {
  name: 'ResumeDiagnosticReport', //简历诊断报告详情页面
  components: {
    ResumReportHeade,
    ResumReportFoot,
    basicInformationComponent
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
        callback()
      } else {
        return callback(new Error('请输入正确的邮箱'))
      }
    }
    return {
      obj: null,
      userId: null, //用户id
      // userInfo: {},
      callBackObj: {}, //简历报告内容
      dataMax: [], //雷达图数据
      dataMaxDtat: [], //雷达图数值
      loadingSubmit: false, // 发送邮箱的确定按钮状态
      dialogVisible: false, // 弹窗状态
      upyunData: { policy: '', signature: '' },
      form: {
        // phone: '', // 手机号
        email: '', // 邮箱
        resume_url: '' // pdf在线预览下载链接
      },
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' },
          { validator: validateEmail, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  created() {
    //用户id
    this.userId = this.$route.query.id
  },
  async mounted() {
    // await this.getResumeGenerateContentFn() //获取用户信息
    await this.getResumeDiagnosisReportFn() //获取简历诊断报告
    this.drawLine() //调用雷达图
  },
  methods: {
    ...mapActions([
      'getResumeDiagnosisReport',
      'getResumeAnalysisEmail',
      'getUpYunSign',
      'resumeFileUpyunPdf'
    ]),
    // async getResumeGenerateContentFn() {
    //   this.userInfo = await this.getResumeGenerateContent({ noLoading: false })
    // },
    //获取简历诊断报告的接口
    async getResumeDiagnosisReportFn() {
      this.callBackObj = await this.getResumeDiagnosisReport(this.$route.query.id)
      this.dataMax = [
        {
          name: this.callBackObj.base_info_title,
          value: this.callBackObj.basic_info_score,
          max: this.callBackObj.basic_info_total_score
        },
        {
          name: this.callBackObj.post_match_title,
          value: this.callBackObj.post_match_score,
          max: this.callBackObj.post_match_total_score
        },
        {
          name: this.callBackObj.other_info_title,
          value: this.callBackObj.other_info_score,
          max: this.callBackObj.other_info_total_score
        },
        {
          name: this.callBackObj.structure_title,
          value: this.callBackObj.structure_score,
          max: this.callBackObj.structure_total_score
        },
        {
          name: this.callBackObj.personal_exp_title,
          value: this.callBackObj.personal_exp_score,
          max: this.callBackObj.personal_exp_total_score
        }
      ]
      this.dataMax.forEach(item => this.dataMaxDtat.push(item.value))
    },
    //雷达图的方法
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      let dataMax = this.dataMax
      let option = {
        radar: {
          axisName: {
            fontSize: '13',
            formatter: (a, b) => {
              return `${a}:${b.value}`
            },
            color: '#383A4D'
          },
          indicator: dataMax,
          radius: '80%'
        },
        series: [
          {
            type: 'radar',
            label: {
              show: false //显示数值
            },
            areaStyle: {}, //每个雷达图形成一个阴影的面积
            data: [
              {
                value: this.dataMaxDtat
              }
            ]
          }
        ]
      }
      myChart.setOption(option)
    },
    // 当用户选择打印
    printerFn() {
      this.$print(this.$refs.print)
    },
    // 当用户点击了一键导出
    async printFn() {
      await this.getUpYunSignFn()
      // son防止文字截断的class名  此方法作用是转成base64
      const pdf = new PdfLoader(
        document.getElementById('print-content'),
        'fileName',
        'question-table'
      )
      pdf.outPutPdfFn('customFileName').then(base64PDF => {
        // 处理 base64PDF 数据
        this.UploadPdf(base64PDF)
      })
    },
    // 上传pdf接口
    async UploadPdf(res) {
      // res拿到base64的pdf
      let pdfBase64Str = res
      let title = this.name
      // 把简历名称存起来方便resumeFileUpyunFn接口取值
      sessionStorage.setItem('resume_title', title)
      let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
      await this.resumeFileUpyunFn(file)
    },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传简历
    async resumeFileUpyunFn(file) {
      const data = new FormData()
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)
      const obj = await this.resumeFileUpyunPdf(data)
      const oJson = {
        id: this.userId,
        resume_url: upyunUrl + obj.url,
        resume_name: this.resumeName,
        file_size: obj.file_size,
        all_result_n: null
      }
      this.form.resume_url = oJson.resume_url // 赋值链接
      this.dialogVisible = true
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    close() {
      window.close()
    },
    // 发送简历至用户邮箱的方法
    async getResumeAnalysisEmailFn() {
      this.loadingSubmit = true
      const res = await this.getResumeAnalysisEmail(this.form)
      if (res && res.code === 200) {
        this.$message({
          message: '发送成功,请注意查收邮箱',
          type: 'success'
        })
      } else {
        this.$message.error('服务器繁忙,请稍后重试')
      }
      this.loadingSubmit = false
      this.resetForm()
    },
    // 当用户弹窗点击了确定
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getResumeAnalysisEmailFn()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 用户弹窗点击了取消
    resetForm() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      location.reload() // 因为导出会让页面截断 重新刷新页面解决
    }
  }
}
</script>

<style scoped lang="stylus">
.title-top
  width 100%
  height 62px
  background #FFFFFF
  box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
  box-sizing border-box
  display flex
  justify-content center
  position: fixed
  top: 0
  left: 0
  z-index: 999
  .TopBox
    width 1123px
    height 62px
    display flex
    justify-content space-between
    align-items center
  .btn-box
    display flex
    align-items center
  .pdfBT
    cursor pointer
    width 88px
    height 34px
    background #003DBB
    border-radius 4px
    font-size 16px
    font-family PingFang SC
    font-weight 500
    color #FFFFFF
    line-height 30px
    text-align center
  .pdfBT:hover
    opacity 0.8
  .return
    cursor pointer
    display flex
    align-items center
    left 610px
    margin-top 4px
    .return-img
      width 11px
      height 11px
      margin-right 6px
    .return-text
      font-size 16px
      font-family PingFang SC
      font-weight 500
      color #333333
.typeArea
  box-sizing border-box
  width 1240px
  margin 62px auto 0px auto
  padding 0px 61px 10px 61px
  height auto
  .ScorimgData
    box-sizing border-box
    margin-top 19px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 35px 25px 35px
    .ScorimgDataTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .ScorimgDataCenten
      display flex
      justify-content space-between
      .CentenOne
        box-sizing border-box
        width 250px
        height 290px
        padding 10px 20px 50px 24px
        background url('~@/assets/image/resume/bg.png') no-repeat center center
        border-radius: 6px
        .OneNumber
          font-size 60px
          font-weight 500
          color #FFFFFF
          .Fen
            margin-left 0px
            display inline-block
            font-size 23px
            font-weight 500
            color #FFFFFF
        .Evaluation
          font-size 22px
          font-weight 500
          margin-bottom 10px
          color #FFFFFF
        .Assess
          font-size 16px
          font-weight 400
          color #FFFFFF
          line-height 33px
      .ThreeEnd
        background url('~@/assets/image/resume/bg(1).png') no-repeat center center
        box-sizing border-box
        width 210px
        height 290px
        padding 20px 15px 20px 20px
        border-radius: 6px
        .Yleld
          width 161px
          box-sizing border-box
          border-bottom 1px dashed #878A99
          padding-bottom 18px
          padding-left 10px
          .BoxImg
            width 45px
            height 45px
            img
              width 100%
              height 100%
        .YleldBox
          display flex
          align-items center
          .BoxRigth
            margin-left 10px
            .Percent
              font-size 30px
              color #383A4D
              font-weight 500
            .Pass
              font-size 14px
              font-weight 400
              color #383A4D
              opacity 0.6
      .ThreeBoottomBox
        margin-top 20px
        .boxOne
          padding 5px 0 0 0
          width 161px
          min-height 63px
          height auto
          background #FFFFFF
          border 1px solid #878A99
          border-radius 5px
          .internet
            text-align center
            font-size 20px
            font-weight normal
            color #383A4D
          .diagnose
            text-align center
            font-size 14px
            font-weight 400
            color #383A4D
            opacity 0.6
        .boxTow
          padding 5px 0 0 0
          margin-top 15px
          width 161px
          height 63px
          background #FFFFFF
          border 1px solid #878A99
          border-radius 5px
          .internet
            text-align center
            font-size 23px
            font-weight normal
            color #383A4D
          .diagnose
            text-align center
            font-size 14px
            font-weight 400
            color #383A4D
            opacity 0.6
  .CapacityTerm
    box-sizing border-box
    margin-top 19px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 35px 34px 35px
    .CapacityTermTop
      display flex
      align-items center
      margin-bottom 30px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
  .TabularList
    display flex
    justify-content space-between
    .ListTable
      width 335px
      height auto
      border-spacing 0
      border-radius 8px
      border solid 1px #dfdfdf
      overflow hidden
      th, td
        text-align center
        border 1px solid rgb(233, 234, 238)
        word-wrap break-word
      tr th
        background-color #eee
        height 50px
        font-size 16px
        font-weight 500
        color #9899AA
      .TdImg
        padding-left 33px
        img
          width 19px
          height 19px
  // 第二页css样式
  .OtherInformation
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 35px 34px 35px
    .MtainBox
      display flex
      justify-content space-between
      .BoxLeft
        .informationTop
          display flex
          align-items center
          margin-bottom 20px
          .mationImg
            width 15px
            height 15px
            margin-right 8px
            img
              width 100%
              height 100%
          .mationText
            font-size 21px
            font-weight bold
            color #383A4D
            margin-right 5px
          .Finalscore
            margin-top 5px
            display inline-block
            font-size 19px
            font-weight 400
            color #008AFF
          .TotalScore
            margin-top 5px
            font-size 19px
        .BoxLeftTop
          position relative
          margin-bottom 16px
          box-sizing border-box
          padding 20px 30px 0px 20px
          width 480px
          min-height 190px
          height auto
          background #F1F3F7
          border-radius 10px
          .BoxImg
            top 123px
            position absolute
            transform translate(214%, -29%)
            width 80px
            height 80px
          .dashed
            width 118px
            height 35px
            background #008AFF
            border-radius 6px
            text-align center
            line-height 34px
            margin-bottom 15px
            span
              font-size 16px
              color #fff
              font-weight 500
          .BasiCentenTxet
            font-size 16px
            font-weight bold
            color #383A4D
            margin-bottom 10px
          .BasiBottomtextBox
            margin-bottom 10px
            div
              color #4b4d5f
              font-size 16px
              line-height 26px
        .BoxLeftBootom
          position relative
          box-sizing border-box
          padding 20px 35px 20px 20px
          width 480px
          min-height 190px
          height auto
          background #F1F3F7
          border-radius 10px
          .Boximg
            top 123px
            position absolute
            transform translate(214%, -29%)
            width 80px
            height 80px
          .dashed
            width 118px
            height 35px
            background #F08E3F
            border-radius 6px
            text-align center
            line-height 28px
            margin-bottom 15px
            span
              font-size 16px
              color #fff
              font-weight 500
          .BasiCentenTxet
            font-size 16px
            font-weight bold
            color #383A4D
            margin-bottom 10px
          .BasiBottomtextBox
            margin-bottom 10px
            div
              color #383A4D
              font-size 16px
              line-height 28px
      .solid
        height auto
        border-right 1px dashed #383A4D
        margin-top 55px
  // 第二页的布局样式
  .basicBox
    box-sizing border-box
    margin-top 34px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 35px 34px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
        margin-right 5px
      .Finalscore
        margin-top 5px
        display inline-block
        font-size 19px
        font-weight 400
        color #008AFF
      .TotalScore
        margin-top 5px
        font-size 19px
    .BasiLayout
      display flex
      justify-content space-between
      .BasiLaoutOne
        position relative
        box-sizing border-box
        padding 20px 38px 20px 20px
        width 480px
        min-height 190px
        height auto
        background #F1F3F7
        border-radius 10px
        .BoxImg
          position absolute
          transform translate(227%, -57%)
          top 76%
          width 80px
          height 80px
          img
            width 100%
            height 100%
        .dashed
          width 118px
          height 35px
          background #008AFF
          border-radius 6px
          text-align center
          line-height 28px
          margin-bottom 15px
          span
            font-size 16px
            color #fff
            font-weight 500
        .BasiCentenTxet
          font-size 16px
          font-weight bold
          color #383A4D
          margin-bottom 10px
        .BasiBottomtextBox
          margin-bottom 10px
          div
            color #383A4D
            font-size 16px
            line-height 28px
          .BoxImg
            position absolute
            transform translate(227%, -57%)
            top 76%
            width 80px
            height 80px
            img
              width 100%
              height 100%
      .Solid
        height auto
        border-right 1px dashed #383A4D
      .BasiLaoutTow
        box-sizing border-box
        padding 20px 35px 20px 20px
        width 480px
        height auto
        background #F1F3F7
        border-radius 10px
        position relative
        .TowImg
          position absolute
          transform translate(227%, -57%)
          top 76%
          width 80px
          height 80px
          img
            width 100%
            height 100%
        .dashed
          width 118px
          height 35px
          background #F08E3F
          border-radius 6px
          text-align center
          line-height 28px
          margin-bottom 15px
          span
            font-size 16px
            color #fff
            font-weight 500
        .BasiCentenTxet
          font-size 16px
          font-weight bold
          color #383A4D
          margin-bottom 10px
        .BasiBottomtextBox
          margin-bottom 10px
          div
            color #383A4D
            font-size 16px
            line-height 28px
.acticedBtn
  position fixed
  top 50%
  right 0
/deep/ .el-dialog
  border-radius 10px
/deep/ .el-button.printerBtn.el-button--default
        width 88px
        height 34px
        border-radius 4px
        font-size 16px
        font-weight 500
        color #003dbb
        display flex
        align-items center
        margin-right 8px
/deep/ .el-input__inner:focus
        border-color #003dbb
</style>
